import {FC, useCallback, useEffect, useState} from "react"
import {
  useConnection,
  useWallet,
} from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram } from "@solana/web3.js";

import { actions, utils, programs, NodeWallet} from '@metaplex/js'; 
import { clusterApiUrl, Connection, Keypair, LAMPORTS_PER_SOL } from '@solana/web3.js';

const Dapp: FC = () => {

  const adminPrivK = [53,129,223,118,229,230,30,176,153,139,239,203,1,82,33,122,79,149,100,162,134,191,227,102,129,166,130,94,104,35,60,110,69,251,14,99,55,216,235,90,166,219,45,29,64,167,205,31,151,215,5,238,224,229,132,215,213,147,40,86,120,216,32,229]
  // const adminPrivK = [45,123,170,211,40,154,41,202,105,219,50,50,93,69,138,167,106,5,215,57,252,176,105,240,87,5,29,45,122,176,105,11,188,154,158,122,220,45,37,197,243,207,144,18,129,158,55,36,107,180,107,16,124,149,186,0,53,157,249,240,201,11,2,91]
  const adminSeed = Uint8Array.from(adminPrivK)
  const adminKeyP = Keypair.fromSecretKey(adminSeed)

  const adminPubK = adminKeyP.publicKey
  const adminSecK = adminKeyP.secretKey

  const { connection } = useConnection();
  const { publicKey } = useWallet();

  const lamportsPerSol = 1000000000

  const dpOgTokenAddress = new PublicKey("4C16U8QKN6jc6LNcxYthRmTXvg211Zu4UZNjppTFZqAu")
  const dpManagementTokenAddress = new PublicKey("4C16U8QKN6jc6LNcxYthRmTXvg211Zu4UZNjppTFZqAu")
  const dpOgFundTokenAddress = new PublicKey("4C16U8QKN6jc6LNcxYthRmTXvg211Zu4UZNjppTFZqAu")

  const [balance, setBalance] = useState<number>()
  const [apiRequest, setApiRequest] = useState<any>("Initial Value")

  const [dpOgSharesBalance, setDpOgSharesBalance] = useState<number>(0)
  const [dpManagementFundSharesBalance, setDpManagementFundSharesBalance] = useState<number>(0)
  const [dpOgFundSharesBalance, setDpOgFundSharesBalance] = useState<number>(0)  
  
  const adminAirdrop = useCallback(async () => {
    if (publicKey) {
      
      const feePayerAirdropSignature = await connection.requestAirdrop(adminPubK, LAMPORTS_PER_SOL);
      await connection.confirmTransaction(feePayerAirdropSignature);

      console.log(feePayerAirdropSignature)

      console.log('Admin Airdrop done')}
  }, [publicKey, connection]);

  const userAirdrop = useCallback(async () => {
    if (publicKey) {
      
      const feePayerAirdropSignature = await connection.requestAirdrop(publicKey, LAMPORTS_PER_SOL);
      await connection.confirmTransaction(feePayerAirdropSignature);

      console.log(feePayerAirdropSignature)

      console.log('User Airdrop done')}
  }, [publicKey, connection]);
  
  const dappNFTMint = useCallback(async () => {
    if (publicKey) {

      // Upload NFT img and get link
      // Upload NFT metadata with NFT img Link and get NFT metadata URI

      const adminWallet = new NodeWallet(adminKeyP)

      // const mintNFTResponse = await actions.mintNFT({
      //   connection,
      //   wallet: adminWallet,
      //   uri: 'https://arweave.net/a6c5ubFKgL3IMGi5dGJL7HbOf-dbvABQdY48NKa7nqI',
      //   maxSupply: 1
      // });

      const mintNFTResponse = await actions.mintNFT ({
        connection,
        wallet: adminWallet,
        uri: 'https://arweave.net/a6c5ubFKgL3IMGi5dGJL7HbOf-dbvABQdY48NKa7nqI',
        maxSupply: 1
      });

      console.log(mintNFTResponse)      
      console.log('NFT Minted')}
      
      const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: adminPubK,
            toPubkey: dpOgTokenAddress,
            lamports: 1,
        })
      );

  }, [publicKey, connection]);

  // onClick function doesn't change anything on wallet update.
  const transferNFT = useCallback(async () => {
    if (publicKey) {
      
      const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: publicKey,
            toPubkey: dpOgTokenAddress,
            lamports: 1,
        })
      );

      console.log(transaction);
    }
    
    console.log(publicKey)
  }, [publicKey, connection]);

  // Function to make a GET request to localhost
  const getApiRequest = useCallback(async () => {
    if (publicKey) {

      const response = await fetch('http://127.0.0.1:8000/api/hello');
      setApiRequest(response);
      console.log(apiRequest);
      
    }
  }, [publicKey, connection]);
  
  // Update window in real time (each time window is rendered)
  useEffect(() => {
    (async () => {
      if (publicKey) {
        const balance = await connection.getBalance(publicKey);
        setBalance(balance / lamportsPerSol);

        // const dpOgTokenAccount = await connection.getParsedTokenAccountsByOwner(publicKey, {mint:dpOgTokenAddress});
        // const dpOgSharesBalance = await connection.getTokenAccountBalance(dpOgTokenAccount.value[0].pubkey);
        // setDpOgSharesBalance(parseInt(dpOgSharesBalance.value.amount));

        // const dpManagementFundTokenAccount = await connection.getParsedTokenAccountsByOwner(publicKey, {mint:dpManagementTokenAddress});
        // const dpManagementFundSharesBalance = await connection.getTokenAccountBalance(dpManagementFundTokenAccount.value[0].pubkey);
        // setDpManagementFundSharesBalance(parseInt(dpManagementFundSharesBalance.value.amount));
        
        // const dpOgFundTokenAccount = await connection.getParsedTokenAccountsByOwner(publicKey, {mint:dpOgFundTokenAddress});
        // const dpOgFundSharesBalance = await connection.getTokenAccountBalance(dpOgFundTokenAccount.value[0].pubkey);
        // setDpOgFundSharesBalance(parseInt(dpOgFundSharesBalance.value.amount));
      }
    })();
  }, [publicKey, connection])

  return (
    <div className="row row-cols-sm-1 m-0 alert alert-warning py-5">
      <div className="d-flex flex-wrap px-5 justify-content-center">
        <h4>Excuse us, we want to build a Dapp for you and this is a space to test a connection to the Solana Blockchain from this website.</h4>
      </div>

      {!publicKey && (
        <div className="d-flex flex-wrap px-5 justify-content-center">
          <p>If you want to test it, please connect your wallet just to check some of your wallet information, if not, just ignore this section.</p>
        </div>
      )}

      {publicKey && (
        <div>
          <div className="px-4 py-2 mt-3 border border-dark">
            <h5>General</h5>
            <div className="d-flex flex-wrap justify-content-left">
              <p className="m-0 fw-bold">Wallet Address: &nbsp;</p>
              <p className="m-0">{publicKey?.toBase58()}</p>
            </div>
            <div className="d-flex flex-wrap justify-content-left">
              <p className="m-0 fw-bold">Balance: &nbsp;</p>
              <p className="m-0">{balance}</p>
            </div>
          </div>
          <div className="px-4 py-2 mt-3 border border-dark">
            <h5>Transfer SOL</h5>
            <div className="d-flex flex-wrap p-1 justify-content-left">
              <span className="input-group-text">Receiver:</span>
              <input type="text" />
            </div>
            <div className="d-flex flex-wrap p-1 justify-content-left">
              <span className="input-group-text">Ammount:</span>
              <input type="text" aria-label="lala"/>
            </div>
            <div className="d-flex flex-wrap p-1 justify-content-left">
              <button onClick={adminAirdrop} disabled={!publicKey}>
                Airdrop to Admin{!publicKey && <p>Only works if a wallet is connected</p>}
              </button>
              <button onClick={userAirdrop} disabled={!publicKey}>
                Airdrop to Me{!publicKey && <p>Only works if a wallet is connected</p>}
              </button>
              <button onClick={dappNFTMint} disabled={!publicKey}>
                Mint NFT{!publicKey && <p>Only works if a wallet is connected</p>}
              </button>
              <button onClick={transferNFT} disabled={!publicKey}>
                Transfer Button{!publicKey && <p>Only works if a wallet is connected</p>}
              </button>
              <button onClick={getApiRequest} disabled={!publicKey}>
                Test API{!publicKey && <p>Only works if a wallet is connected</p>}
              </button>
              <div className="d-flex flex-wrap justify-content-left">
                <p className="m-0 fw-bold">API Response: &nbsp;</p>
                <p className="m-0">{apiRequest}</p>
              </div>
            </div>
          </div>
          {dpOgSharesBalance > 0 && (
            <div className="px-4 py-2 mt-3 border border-dark">
              <h5>dp OGs' Dashboard</h5>
              <div className="d-flex flex-wrap justify-content-left">
                <p className="m-0 fw-bold">OG Shares: &nbsp;</p>
                <p className="m-0">{dpOgSharesBalance}</p>
              </div>
            </div>
          )}
          {dpManagementFundSharesBalance > 0 && (
            <div className="px-4 py-2 mt-3 border border-dark">
              <h5>dp Management Fund Members' Dashboard</h5>
              <div className="d-flex flex-wrap justify-content-left">
                <p className="m-0 fw-bold">Management Shares: &nbsp;</p>
                <p className="m-0">{dpManagementFundSharesBalance}</p>
              </div>
            </div>
          )}
          {dpOgFundSharesBalance > 0 && (
            <div className="px-4 py-2 mt-3 border border-dark">
              <h5>dp OG Fund Members' Dashboard</h5>
              <div className="d-flex flex-wrap justify-content-left">
                <p className="m-0 fw-bold">OG Fund Shares: &nbsp;</p>
                <p className="m-0">{dpOgFundSharesBalance}</p>
              </div>
            </div>
          )}
        </div>
      )}

    </div>
);
  };

export default Dapp