import { FC, useMemo, useEffect, useState, useCallback, MouseEventHandler} from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"

import Home from "./Home";
import Dapp from "./Dapp";
import Mint from "./Mint";

import { WhitepaperEN, WhitepaperES, Roadmap, TC} from "./MdFiles"

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  /// Part 1. Solana Wallet Conection
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(() => [
    getPhantomWallet(),
    getSlopeWallet(),
    getSolflareWallet(),
    getTorusWallet({
      options: { clientId: 'Get a client ID @ https://developer.tor.us' }
    }),
    getLedgerWallet(),
    getSolletWallet({ network }),
    getSolletExtensionWallet({ network }),
  ], [network]);

  return (
    <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletModalProvider>
              <Router>
                <main>

                  <nav className="navbar navbar-expand-md navbar-light bg-white sticky-top px-3">
                    <a className="navbar-brand px-sm-2 px-md-5" href="/"><h1>dp</h1></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse flex-row-reverse" id="navbarSupportedContent">
                      <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item">
                          <a className="nav-link active px-3 border-end" href="/#About">Acerca De</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link active px-3 border-end" href="/Mint">Ser Miembro</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link active px-3" href="/Dapp">Dapp</a>
                        </li>
                        <li className="nav-item px-2">
                          <WalletMultiButton />
                        </li>
                      </ul>
                    </div>
                  </nav>

                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/Dapp" element={<Dapp />}></Route>
                    <Route path="/Mint" element={<Mint />}></Route>
                    <Route path="/WhitepaperES" element={<WhitepaperES />}></Route>
                    <Route path="/WhitepaperEN" element={<WhitepaperEN />}></Route>
                    <Route path="/Roadmap" element={<Roadmap />}></Route>
                    <Route path="/Terms" element={<TC />}></Route>
                  </Routes>

                  <div className="row row-cols-sm-1 py-5 m-0 text-center">
                    <hr className="my-4"/>
                    <div className="col p-3">
                      <p className="m-0">Nada En Este Sitio Constituye Asesoría Financiera</p>
                      <p className="m-0">Siempre Investiga Por Tu Cuenta</p>
                      <a href="/Terms">Términos y Condiciones</a>
                    </div>
                  </div>

                </main>
              </Router>
            </WalletModalProvider>
        </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;