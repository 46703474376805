import {FC, useState, useEffect} from "react"
import ReactMarkdown from 'react-markdown'

const WhitepaperES: FC = () => {
    const [text, setText] = useState('')
    
    useEffect(()=>{
      const mdPath = require("./docs/whitepaperES.md");
    
      fetch(mdPath.default)
        .then(response => {
          return response.text()
        })
        .then(text => setText(text))
    },[])

  return(
    <div className="px-4">
        <ReactMarkdown children={text} />
    </div>
  );
}

const WhitepaperEN: FC = () => {
    const [text, setText] = useState('')
    
    useEffect(()=>{
      const mdPath = require("./docs/whitepaperEN.md");
    
      fetch(mdPath.default)
        .then(response => {
          return response.text()
        })
        .then(text => setText(text))
    },[])
      
  return(
    <div className="px-4">
       <ReactMarkdown children={text} />
    </div>

  );
}

const Roadmap: FC = () => {
    const [text, setText] = useState('')
    
    useEffect(()=>{
      const mdPath = require("./docs/roadmap.md");
    
      fetch(mdPath.default)
        .then(response => {
          return response.text()
        })
        .then(text => setText(text))
    },[])

  return(
    <div className="px-4">
        <ReactMarkdown children={text} />
    </div>

  );
}

const TC: FC = () => {
  const [text, setText] = useState('')
  
  useEffect(()=>{
    const mdPath = require("./docs/tc.md");
  
    fetch(mdPath.default)
      .then(response => {
        return response.text()
      })
      .then(text => setText(text))
  },[])

return(
  <div className="px-4">
      <ReactMarkdown children={text} />
  </div>

);
}

export {WhitepaperES, WhitepaperEN, Roadmap, TC};