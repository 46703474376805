import {FC} from "react"

const Home: FC = () => {

  return(
    <div>
        <div className="row row-cols-sm-1 px-sm-1 px-md-5 pt-5 m-0">
            <div className="jumbotron">
            <br/>
            <h4 className="display-4">Crecimiento Orgánico</h4>
            <h4 className="display-4">Visión a Largo Plazo</h4>
            <div className="d-flex">
                <h4 className="display-4 flex-grow-1">Sin Hype</h4>
                <div className="d-flex py-2 py-md-4">
                    <a className="px-1" target="_blank" href="https://twitter.com/decentralized_p"><img src="https://cdn-icons-png.flaticon.com/512/733/733579.png" alt="" width="30"/></a>
                    <a className="px-1" target="_blank" href="https://www.instagram.com/decentralizedprofits/"><img src="https://cdn-icons-png.flaticon.com/512/174/174855.png" alt="" width="30"/></a>
                    <a className="px-1" target="_blank" href="https://discord.gg/rPqYkv2T"><img src="https://cdn-icons-png.flaticon.com/512/2111/2111370.png" alt="" width="30"/></a>
                </div>
            </div>
            <hr className="my-4"/>
            <p>Organización Autónoma Descentralizada (DAO) hecha para maximizar el beneficio de sus miembros y más.</p>
            {/* <p>Decentralized Autonomous (and Anonymous) Organization as Investment Fund, built to maximize its members' benefits and more.</p> */}
            </div>
        </div>

        <div className="row row-cols-sm-1 m-0 alert alert-warning py-5">
            <div className="d-flex flex-wrap px-5 justify-content-center">
                <h4>Hay más en camino, nuestro sitio web aún está en desarrollo.</h4>
            </div>
        </div>

        <div className="row row-cols-sm-1 pt-5 px-5 m-0">
            <div className="d-flex flex-wrap justify-content-center">
                <h5>Acceso a Educación y Oportunidades de Inversión Colectiva Rentable y Responsable.</h5>
            </div>
        </div>

        <div className="row row-cols-sm-1 pt-4 m-0" id="About">
            <div className="d-flex flex-wrap justify-content-center pt-5 pb-1">
                <h2>Acerca De</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-left px-sm-0 px-md-5">
                <p> 
                    Decentralized Profits es la transformación y evolución del sueño de una pequeña firma de gestión de activos en Latinoamérica que empezó de ceros.
                    Por esto, en su corta pero rentable historia identificó, que las mejores oportunidades de inversión además de ser más fáciles de administrar
                    y escalar con mayores recursos, usualmente no están disponibles al público, y por situaciones de mercado como practicidad y conexiones, tienden a estar
                    concentradas en unos sectores y grupos de personas con gran poder adquisitivo ya definidos, generando un flujo que incrementa la desigualdad financiera.
                </p>
                <p>
                    Adicionalmente, la inflación y devaluación de las monedas tradicionales, sirve como motivación para buscar refugios y alternativas de inversión respecto
                    a los mercados tradicionales y no tradicionales. Es por esto que Decentralized Profits evoluciona para ser una solución.
                </p>
            </div>
        </div>

        <div className="row row-cols-sm-1 pt-5 px-5 m-0">
            <div className="d-flex flex-wrap justify-content-center pb-4">
                <h2>En Qué Invertimos</h2>
            </div>
            <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner text-center">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Membresías de Alfa Groups</h2>
                            <div>
                                <img src="https://lh3.googleusercontent.com/I4Zua8rjLn6ZjqUe2xi1sKU2gV34spzM2Wh_IjXKZj1kYjQJg4FVO9vqvR_ccNM8KXlwo0RCBvfJ_lfswBWY4YGQd8ywKWVo-4bn=s168" className="d-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Non Fungible Tokens (NFTs)</h2>
                            <div>
                                <img src="https://sld-babyapes.s3.amazonaws.com/3720.png" className="d-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/VmZK6x0xbnWXNcm64Q_RvQyXOlt0vI7SWkIErycqJD4" className="d-none d-sm-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" className="d-none d-xl-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" className="d-none d-xxl-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Otras Inversiones</h2>
                            <div className="py-4">
                                <p className="m-0">Sector Inmobiliario</p>
                                <p className="m-0">Títulos Valores (Acciones, Bonos, Derivados)</p>
                                <p className="m-0">Moneda Extranjera</p>
                                <p className="m-0">Finanzas Descentralizadas (DeFi)</p>
                                <p className="m-0">Tecnología Blockchain e Inteligencia Artificial</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Real Estate</h2>
                            <div>
                                <img src="https://sld-babyapes.s3.amazonaws.com/3720.png" className="d-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/VmZK6x0xbnWXNcm64Q_RvQyXOlt0vI7SWkIErycqJD4" className="d-none d-sm-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" className="d-none d-xl-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" className="d-none d-xxl-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div> 
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Bonds and Securities</h2>
                            <div>
                                <img src="https://sld-babyapes.s3.amazonaws.com/3720.png" className="d-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/VmZK6x0xbnWXNcm64Q_RvQyXOlt0vI7SWkIErycqJD4" className="d-none d-sm-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" className="d-none d-xl-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" className="d-none d-xxl-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Blockchain Technology & Cryptocurrencies</h2>
                            <div>
                                <img src="https://sld-babyapes.s3.amazonaws.com/3720.png" className="d-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/VmZK6x0xbnWXNcm64Q_RvQyXOlt0vI7SWkIErycqJD4" className="d-none d-sm-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" className="d-none d-xl-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" className="d-none d-xxl-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Artificial Intelligence</h2>
                            <div>
                                <img src="https://sld-babyapes.s3.amazonaws.com/3720.png" className="d-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/VmZK6x0xbnWXNcm64Q_RvQyXOlt0vI7SWkIErycqJD4" className="d-none d-sm-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" className="d-none d-xl-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" className="d-none d-xxl-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">Decentralized Finances & More</h2>
                            <div>
                                <img src="https://sld-babyapes.s3.amazonaws.com/3720.png" className="d-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/VmZK6x0xbnWXNcm64Q_RvQyXOlt0vI7SWkIErycqJD4" className="d-none d-sm-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" className="d-none d-xl-inline" alt="..." height="175"/>
                                <img src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" className="d-none d-xxl-inline" alt="..." height="175"/>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <div className="col row-cols-md-2 p-0 d-flex flex-wrap justify-content-center">
                            <h2 className="p-5 m-0">What we Invest in</h2>
                            <div>
                                <p className="m-0">Stocks</p>
                                <p className="m-0">Derivatives</p>
                                <p className="m-0">Foreign Currency</p>
                                <p className="m-0">Crypto Staking</p>
                                <p className="m-0">Leveraged Yield Farming</p>
                                <p className="m-0">Crypto, Fungible & Non Fungible Token Portfolio</p>
                                <p className="m-0">Algorithmic & Artificial Intelligence Trading</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        <div className="row row-cols-sm-1 pt-5 m-0">
            <div className="d-flex flex-wrap justify-content-center">
            <h2>Beneficios</h2>
            </div>
            <div className="d-flex flex-wrap py-3 justify-content-center">
            <p>Ser miembro de decentralized profits te da acceso a diferentes beneficios y siempre trabajaremos por mas...</p>
            </div>
            <div className="row row-cols-sm-1 row-cols-md-3 px-5 m-0">
            {/* <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Profits</h5>
                <p className="card-text">Profits distribution from dp investments.</p>
                </div>
            </div> */}
            <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Crecimiento</h5>
                <p className="card-text">Crecimiento continuo en calidad y beneficios como comunidad.</p>
                </div>
            </div>
            {/* <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Additional perks for holders of multiple tokens</h5>
                <p className="card-text">Coming soon...</p>
                </div>
            </div> */}
            <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Chat y Comunidad</h5>
                <p className="card-text">Herramientas para agregar valor entre los miembros de dp.</p>
                </div>
            </div>
            <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Reportes e Investigación</h5>
                <p className="card-text">Investment research and insights from dp headquarters to members.</p>
                </div>
            </div>
            {/* <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Access to Projects</h5>
                <p className="card-text">Preferential access to future investment opportunities.</p>
                </div>
            </div> */}
            <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Educación DeFi</h5>
                <p className="card-text">Contenido para miembros para aprender sobre finanzas descentralizadas.</p>
                </div>
            </div>
            </div>
        </div>

        {/* <div className="row row-cols-sm-1 pt-5 m-0" id="About">
            <div className="d-flex flex-wrap justify-content-center px-sm-0 px-md-5">
                <h5>Off course, if you want the details, please go and check our:</h5>
            </div>
            <div className="row row-cols-sm-2 pt-5 m-0 text-center">
                <div className="col pt-4">
                <h4>Whitepaper</h4>
                <p><a href="/WhitepaperEN">English</a> or <a href="/WhitepaperES">Spanish</a></p>
                </div>
                <div className="col pt-4">
                <h4>Roadmap</h4>
                <p>Check the growing <a href="/Roadmap">Roadmap</a></p>
                </div>
            </div>
        </div> */}

        <div className="row row-cols-sm-1 pt-5 m-0" id="FAQs">
            <div className="d-flex flex-wrap pt-5 justify-content-center">
            <h2>FAQs</h2>
            </div>
            <div className="accordion accordion-flush px-5" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <h5>Where is dp located?</h5>
                </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">In the metaverse. We are a web and blockchain based community, we are nomads looking for benefits around the universe, the omniverse and the metaverse for our members.</div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    <h5>How to become a member?</h5>
                </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">Hold at least a decentralized profits membership, if you want to get one or more, please go to <a href="/Mint">Become a Member</a> and follow the instructions.</div>
                </div>
            </div>
            {/* <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <h5>How are profits distributed?</h5>
                </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                    This will be discussed and defined with the community through the different communication chanels (mainly, our discord server), but for you to have an idea an option will be that:
                    The first day of each month at 00:00:00 UTC, a holders' snapshot will be taken. Any kind of realized and unrealized but pending to realize profits to be distributed until the snapshots time,
                    will be converted to SOL and in the next 5 days, profits will be sent to the wallet of each holder at the time of the snapshot.
                </div>
                </div>
            </div> */}
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    <h5>Who created and who runs dp?</h5>
                </button>
                </h2>
                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                    dp is always looking for added value and highest performance, so temporarly, all dp decisions are made by the core team, but we will be expanding to add bright minds to the team and will work to implement a kind of DAO-governance model.
                </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    <h5>Does dp already have something or what is it waiting to start?</h5>
                </button>
                </h2>
                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                    dp started with USD $50.000 from founders savings and also, for more than one year founders have been developing an artificial intelligence securities analysis platform that hopefully dp members will benefit from.
                </div>
                </div>
            </div>
            {/* <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    <h5>What is dp going to do with the resources?</h5>
                </button>
                </h2>
                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">We are going to use every penny in investments, some risky some not that much, but the main goal is to give profits and benefits to the members.</div>
                </div>
            </div> */}
            </div>
        </div>

        <div className="row row-cols-sm-1 pt-5 m-0" id="Team">
            <div className="d-flex flex-wrap pt-5 justify-content-center">
            <h2>Team</h2>
            </div>
            <div className="row row-cols-sm-1 row-cols-md-3 m-0 text-center">
            <div className="col p-5">
                <img className="bd-placeholder-img rounded-circle m-3" src="https://sld-babyapes.s3.amazonaws.com/3720.png" width="175" height="175"/>
                <h4>cbro</h4>
                <p className="m-0">Management & Dev</p>
                <p className="m-0">Wealth Hunter</p>
                <p className="m-0">Accountant</p>
                <p className="m-0">Hobbyist Programmer</p>
            </div>
            <div className="col p-5">
                <img className="bd-placeholder-img rounded-circle m-3" src="https://weaver.moonrank.app/RqvES6p92vDK4ipy28d9PFy7fnruXLgmUhcAVky-lhU?ext=png" width="175" height="175"/>
                <h4>scs</h4>
                <p className="m-0">IT & Dev</p>
                <p className="m-0">Wealth Hunter</p>
                <p className="m-0">Economist</p>
                <p className="m-0">MA Economics</p>
                <p className="m-0">MS in Data Science</p>
            </div>
            <div className="col p-5">
                <img className="bd-placeholder-img rounded-circle m-3" src="https://weaver.moonrank.app/K9LkxH9X2cVC2uvaab1bXgk-5xnxg1jxkgIw8RQ2CM8?ext=png" width="175" height="175"/>
                <h4>M.Charry</h4>
                <p className="m-0">Community</p>
                <p className="m-0">Wealth Hunter</p>
                <p className="m-0">Economist</p>
                <p className="m-0">MA Economics</p>
            </div>
            </div>
        </div>
    </div>
  );
}
export default Home;