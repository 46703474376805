import {FC} from "react"

const Mint: FC = () => {

  return(
    <div>
      <div className="row row-cols-sm-1 pt-5 m-0">
        <div className="px-5 pt-2 justify-content-center">
          <p className="m-0">First you will need to have a Solana Wallet. If you don't have one yet, we recommend you to follow this steps:</p>
          <ul>
            <li>Download the <a href="https://slope.finance/" target="_blank">Slope Wallet</a> app.</li>
            <li>Create a Solana wallet.</li>
            <li>Secure your wallet (save your seed phrase properly).</li>
            <li>Get your public key or wallet address.</li>
          </ul>
          <p className="m-0">Other optional but helpful requirements are:</p>
          <ul>
            <li>Have an Instagram account and <a target="_blank" href="https://www.instagram.com/decentralizedprofits/">follow us</a> (to keep in touch).</li>
            <li>Have a Discord account and link <a target="_blank" href="https://discord.gg/rPqYkv2T">conect to our server</a> (to talk with us and the community and for support).</li>
            <li>Have a Twitter account and <a target="_blank" href="https://twitter.com/decentralized_p">follow us</a> (to get updates).</li>
          </ul>
      </div>
      </div>
        <div className="row row-cols-sm-1 pt-2 m-0">
          <div className="d-flex flex-wrap pt-2 justify-content-center">
          <h5>Choose your payment method and follow the instructions.</h5>
          </div>
          <div className="accordion accordion-flush px-5" id="accordionFlushExample">
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <h5>Credit Card</h5>
                </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Please go to our <a target="_blank" href="https://www.buymeacoffee.com/decprof/extras">Buy Me a Coffee web page</a>, buy a OG Fund Share and fill the information that will ask you there:
                    <ul>
                      <li>Wallet address from the member who reffered you (if applies).</li>
                      <li>Your wallet addres.</li>
                    </ul>     
                  </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    <h5>Solana</h5>
                </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">Price is 1 SOL, Please <a target="_blank" href="https://discord.gg/rPqYkv2T">reach us in our discord server</a> to complete the process.</div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Mint;